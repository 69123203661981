import React from "react"
//import { Link } from "gatsby"
import Formsy from 'formsy-react';
import Layout from "../components/layout"
import * as actionCreators from './../store/actions';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import MyInput from "../components/MyInput"
import MyAuto from "../components/MyAuto";
import MySelect from "../components/MySelect";
import ErrrorHandel from "../components/ErrrorHandel";
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";
import { zonar } from './../components/constant'
import SEO from "../components/seo"


class QuotePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordId:null,
            dealertoken:null,
            lead:null,
            vehi:null,
            plans:null,
            noplan:null,
            deducts:null,
            curplan:null,
            planIdx:'plan1',
            planInfo:null,
            dedIdx:null,
            surcList:null,
            canSubmit:false,
            canPay:false,
            optCharges:null,
            totAmt:0,
            showPay:false,
            gatewayerror:null,
            paylnk:1,
            locSurc:{'Commercial':'isCommercial','EnhanchedTech':'isEnhanchedTech','LiftKit':'isLiftKit','RideShare':'isRideshare',
                        'Branded':'isBranded','Canadian':'isCanadian','WaitPeriodWaiver':'isWPW', 'FULLDIV':'FULLDIV','HALFDIV':'HALFDIV',
                        'Seals and Gaskets':'SealsGaskets'},
            yearList: ['Loading...'],
            makeList: ['Loading...'],
            modelList: ['Loading...'],
            msg:"Loading",
            loggeduserid:null,
            userType:'emp',
            sequma:false,
            couponCode:null,
            couponStat:0,
            discountType:null,
            discountVal:0,
            discountAmt:0,
            discountCode:null,
            dateVal:this.getMyDate(0,1),
            initdate:this.getMyDate(0,1),
            postpay:0
        }
    }

    async componentDidMount() {
        const { showLoader} = this.props;
        var {locSurc} = this.state;
        showLoader();
        let param = this.getQueryString(this.props.location.search);
        let loggeduserid = param.loggeduserid ? param.loggeduserid : null;
        let userType = param.utm ? 'customer' : 'emp';
        this.setState({ loggeduserid:loggeduserid,userType:userType});
        this.state.loggeduserid = loggeduserid;
        if(param && param.q){
            let dealertoken = param.dealertoken ? param.dealertoken : null;
            let surList = {};
            let optCharges = {}
            Object.keys(locSurc).forEach((s)=> {
                surList[s] = locSurc[s];
                optCharges[s] = 0;
            })
            this.setState({ surcList:surList,optCharges:optCharges,dealertoken:dealertoken,recordId:param.q});
            await this.getYear(dealertoken);
            await this.getMakes(dealertoken);
            await this.getFromDirect(param.q,dealertoken);
        } else if(param && param.leadid) {
            let dealertoken = param.dealertoken ? param.dealertoken : null;
            let edited = param.edit=='true' ? true : false;
            let dyvin = param.vin=="NOVIN"? '':param.vin;
            await this.addUserFromCRM(param.leadid,edited,dyvin);
        }
        
    }
    
    addUserFromCRM = async(paramId,edited,dyvin) => {
        const { hideLoader} = this.props;
        const {loggeduserid} = this.state;

        let uri = process.env.REST_API_URL+'addusercrm';
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
                edited:edited,
                dyvin:dyvin
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.status){
                window.location.href = "/?q="+res.id+(res.dealertoken?'&dealertoken='+res.dealertoken:'')+(loggeduserid?'&loggeduserid='+loggeduserid:'')
            } else {
                this.setState({ msg: res.msg});
                hideLoader();
            }
        });
    }

    getQueryString = (data) => {
        if (data !== '') {
            const search = data.substring(1);
            return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        }
    }

    noCoupon = () =>{
        this.state.discountAmt = 0;
        this.state.discountVal = 0;
        this.state.discountType = null;
        //console.log("restart",this.state.discountAmt)
        this.setState({ couponCode:null,couponStat:0,discountType:null,discountVal:0,discountAmt:0,discountCode:null});
    }

    getYear = async(dealertoken) => {
        let uri = process.env.REST_API_URL+'getYears'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri)
        .then(res => res.json())
        .then((res) => {
          this.setState({yearList:res});
        });
      }
    
      getMakes = async(dealertoken) => {
        let uri = process.env.REST_API_URL+'getMakes'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri)
        .then(res => res.json())
        .then((res) => {
          this.setState({makeList:res});
        });
      }
    
      getModels = async(make,dealertoken) => {
        console.log(make,dealertoken)
        let uri = process.env.REST_API_URL+'getModels'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              make: make,
          })
        })
        .then(res => res.json())
        .then((res) => {
          this.setState({modelList:res});
        });
      }

    getFromDirect = async(paramId,dealertoken) => {
        const { hideLoader} = this.props;
        var {locSurc} = this.state;

        let uri = process.env.REST_API_URL+'getPlanById'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.data){
                let planInfo = res.data.step3;
                this.setState({ lead: res.data.step4, vehi: res.data.step1, planInfo:res.data.step3});
                
                if(res.data.plans && res.data.plans.plan1){
                    let plans = res.data.plans;
                    //console.log(plans)
                    let planIdx = '';
                    let deducts = {};
                    Object.keys(plans).forEach((idx1)=> {
                        Object.keys(plans[idx1]['deduct']).forEach(function(idx2) {
                            deducts[idx2] = 1;
                        });
                    });
                    Object.keys(plans).forEach((idx)=> {
                        if(plans[idx]['name'] === planInfo.levelOfCoverage){
                            planIdx = idx;
                        }
                    });
                    if(!plans[planIdx]) planIdx = 'plan1';
                    let feat = res.data.step1.vehicleFeature;
                    let optCharges = {}
                    Object.keys(locSurc).forEach((s)=> {
                        if(feat[locSurc[s]])
                            optCharges[s] = plans[planIdx]['surc'][s];
                    })
                    this.setState({ plans: plans, deducts:deducts, planIdx:planIdx,dedIdx:planInfo.payAtClaimTime, curplan:planIdx+planInfo.payAtClaimTime, optCharges:optCharges,showPay:null});
                    this.getTotAmt();
                    
                }
                if(res.data.step1.vehicleInfo.maker)
                    this.getModels(res.data.step1.vehicleInfo.maker,dealertoken);
                this.checkLead(res.data.step2.leadId);
            } else {
                this.setState({ msg: "Link Expired!"});
            }
            if(res.status === 0){
                this.setState({ noplan: "No Plan is available",showPay:null});
            } else {
                this.setState({ noplan: null,showPay:null});
            }
            hideLoader();
        });
    }

    checkLead = (leadId) => {
        let uri = process.env.REST_API_URL+'checkLead/?leadId='+leadId;
        fetch(uri)
        .then(res => res.json())
        .then((res) => {
            if(!res.status){
                this.setState({lead:null, msg: "Link Expired!"});
                console.log("----------------"+this.state.msg)
            }
        });
      }

    disableButton = () => {
        this.setState({ canSubmit: false});
    }
    enableButton = () => {
        const {lead, vehi} = this.state;
        if(lead.state && vehi.vehicleInfo.year && vehi.vehicleInfo.mileage>=0 && vehi.vehicleInfo.maker && vehi.vehicleInfo.model){
            this.setState({ canSubmit: true});
        } else {
            this.setState({ canSubmit: false});
        }
    }

    async vinChanges(form, self, value) {
        const { vehi,dealertoken } = this.state;
        let tmpVehi = vehi;
        let mileage = tmpVehi.vehicleInfo.mileage;
        tmpVehi.vehicleInfo = {vin : value, year: "", maker : "",   model : "", mileage:mileage};
        tmpVehi.vehicleFeature = {isAWD4x4 :  false,isDiesel :  false,isHybrid : false,isElectric : false,isTurboSuper : false,}
        this.noCoupon();
        this.setState({ vehi:tmpVehi, plans:null, showPay:null});
        
        if(value.length === 0){
          await this.getYear(dealertoken);
          await this.getMakes(dealertoken);
        }
        if(value.length===17){
          let uri = process.env.REST_API_URL+'getvindet?vinno='+value+(dealertoken?'&dealertoken='+dealertoken:'');
          await fetch(uri)
          .then(res => res.json())
          .then((resp) => { 
            if(resp.Year){
                tmpVehi.vehicleInfo =  {vin : value, year: resp.Year, maker : resp.Make,   model : resp.Model, mileage:mileage };
                tmpVehi.vehicleFeature = {
                    isAWD4x4 : resp.DriveType==="FourByFour" || resp.DriveType==="AWD" ? true : false,
                    isDiesel : resp.FuelType==="Diesel" ? true : false,
                    isHybrid : resp.FuelType==="Hybrid" ? true : false,
                    isElectric : resp.FuelType==="Electric" ? true : false,
                    isTurboSuper : resp.FuelDelivery==="Turbo" || resp.FuelDelivery==="Supercharged" ? true : false,
                  }
                this.setState({ vehi:tmpVehi,yearList:[resp.Year],makeList:[resp.Make],modelList:[resp.Model]});
                this.state.vehi = tmpVehi;
                this.setState({ vehi:tmpVehi});
            } else {
                if (!this.state.isValueChangeError) {
                    this.setState({ isValueChangeError: true }, () => {
                        self.refs[`${form}`].updateInputsWithError({
                        'vehicleInfo.vin': 'Sorry, Please enter correct VIN.'
                        });
                        this.setState({ isValueChangeError: false })
                    })
                }
            }
          });
        } 
    }
    sugChange = async(value,type) =>{

        const { lead,vehi,dealertoken } = this.state;
        let tmpVehi = vehi;
        let tmpLead = lead;
        if (type === 'make') {
            tmpVehi.vehicleInfo.maker = value;
            tmpVehi.vehicleInfo.model = '';
            this.setState({ vehi: tmpVehi,modelList:[]});
            await this.getModels(value,dealertoken);
        } else  if (type === 'year') {
            tmpVehi.vehicleInfo.year = value;
            this.setState({ vehi: tmpVehi });
        } else  if (type === 'model') {
            tmpVehi.vehicleInfo.model = value;
            this.setState({ vehi: tmpVehi});
        } else  if (type === 'zone') {
            tmpLead.state = value;
            tmpVehi.vehicleInfo.zone = value
            this.setState({ lead: tmpLead,vehi:tmpVehi });
        }
        this.setState({ plans: null,showPay:null });
        this.enableButton();
    }

    radioChanges = (ele, mythis, value) => {
        const { vehi } = this.state;
        let tmpVehi = vehi;
        tmpVehi.vehicleFeature[ele] = value;
        if(ele==='isDiesel' && value) {
            tmpVehi.vehicleFeature.isHybrid = false;
            tmpVehi.vehicleFeature.isElectric = false;
        } else if(ele==='isHybrid' && value) {
            tmpVehi.vehicleFeature.isDiesel = false;
            tmpVehi.vehicleFeature.isElectric = false;
        } else if(ele==='isElectric' && value) {
            tmpVehi.vehicleFeature.isHybrid = false;
            tmpVehi.vehicleFeature.isDiesel = false;
        }
        this.noCoupon();
        this.setState({ vehi: tmpVehi, plans:null, showPay:null});
    }

    getTotAmt = () =>{
        var { planIdx,dedIdx,optCharges,plans,discountType, discountAmt, discountVal } = this.state;
        let amt = plans[planIdx]['actual'] + plans[planIdx]['deduct'][dedIdx];
        //console.log("start",amt,discountAmt);
        Object.keys(optCharges).map((k)=> {
            if(optCharges[k]){
                if(plans[planIdx]['surc'][k]){
                    amt = amt + optCharges[k];
                } else {
                    optCharges[k] = 0;   
                }
            }
        });

        //console.log("mid",amt,discountAmt);
        if(discountType == 'f'){
            discountAmt = discountVal;
        } else if (discountType == 'p'){
            discountAmt = Math.round(amt * discountVal / 100,0);
        }
        amt = amt - discountAmt;
        //console.log(amt, discountAmt);
        this.setState({ totAmt: amt,discountAmt:discountAmt })
        
    }

    mailcheck = (value) => {
        this.setState({ sequma: value })
    }
    postpay = (value) => {
        this.setState({ postpay: value })
        if(!value)
            this.setState({ dateVal: this.getMyDate(0,1) })
        
    }
    surcChanges = (ele, mythis, value, amt) => {

        const { optCharges} = this.state;
        let tmpOpt = optCharges;
        this.noCoupon();
        //console.log(ele)
        if(value){
            tmpOpt[ele] = amt;
            if(ele==="FULLDIV") tmpOpt["HALFDIV"]=0;
            else if(ele==="HALFDIV") tmpOpt["FULLDIV"]=0;

            if(ele==="RideShare") tmpOpt["Commercial"]=0;
            else if(ele==="Commercial") tmpOpt["RideShare"]=0;

        } else {
            tmpOpt[ele] = 0;
        }
        this.setState({ optCharges: tmpOpt,showPay:null })
        this.getTotAmt();

       
        
    }
    
    tbvalChanges = (val) => {
        this.noCoupon();
        this.setState({ plans:null, showPay:null});
    }
    changePlan = (planId,deductId,dedPrice, actprice) => {
        this.noCoupon();
        if(dedPrice>=-100){
            this.setState({curplan:(planId+deductId),planIdx:planId,dedIdx:deductId, showPay:null})
            this.state.curplan = planId+deductId;
            this.state.planIdx = planId;
            this.state.dedIdx = deductId;
            this.getTotAmt();
        }
    }

    changeForm = async(model) => {
        if(this.state.doneForm){
            this.setState({plans:null});
            this.noCoupon();
            console.log('Form Changed')
        }
    }

    submit = async(model) => {
        const { showLoader, hideLoader } = this.props
        const {dealertoken, vehi, lead, recordId, planInfo, loggeduserid} = this.state;
        if(lead.state && vehi.vehicleInfo.year && vehi.vehicleInfo.maker && vehi.vehicleInfo.model){
            this.noCoupon();
            showLoader();
            let tmpVehi = vehi;
            model.zone = lead.state;
            model.vehicleInfo.zone = lead.state;
            model.vehicleInfo.year = vehi.vehicleInfo.year;
            model.vehicleInfo.maker = vehi.vehicleInfo.maker;
            model.vehicleInfo.model = vehi.vehicleInfo.model;
            model.vehicleInfo.year = vehi.vehicleInfo.year;
            model.campaign = null;
            model.plans = null;
            tmpVehi.vehicleInfo = model.vehicleInfo;
            this.setState({vehi:tmpVehi})
            model.loggeduserid = loggeduserid;
            model.calfrm = 'crm';
            model.userId = planInfo.userId;
            model.vehicleId = planInfo.vehicleId;
            model.recordId = planInfo.recordId;
            model.leadId = lead.leadId;
            model.oldemail = lead.email;


            let uri = process.env.REST_API_URL+'adduser'+(dealertoken?'/?dealertoken='+dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(model)
            })
            .then(res => res.json())
            .then(async(resp) => { 
                if (resp.data.status==1) {
                    
                    if(recordId==resp.data.recordId){
                        await this.getFromDirect(resp.data.recordId,dealertoken)
                    }
                    else {
                        window.location.href = "/?q="+resp.data.recordId+(dealertoken?'&dealertoken='+dealertoken:'')+(loggeduserid?'&loggeduserid='+loggeduserid:'')
                    }
                } else {
                    hideLoader();
                    this.setState({ noplan: "Email is already exist",showPay:null});
                }
            });
        } else {
            this.setState({ noplan: "No Plan is available",showPay:null});
        }
    }
    
    getBreakUp =() =>{
        var {optCharges, plans, planIdx, dedIdx, totAmt} = this.state;
        
        var ded_code = 0;
        var nowPlan = plans[planIdx];
        
        nowPlan['deductibles'].forEach((tmp) =>{
            if(parseInt(tmp.amt) === parseInt(dedIdx)){
                ded_code = tmp.id;
            }
        });

        
        console.log('totAmt : '+totAmt)
        var seals = 0;
        var dividendType = "";
        var dividendPrice = "";

        if(optCharges['Seals and Gaskets']>0){
            totAmt = totAmt - optCharges['Seals and Gaskets'];
            seals = optCharges['Seals and Gaskets'];
        }
        if(optCharges['FULLDIV']>0){
            totAmt = totAmt - optCharges['FULLDIV'];
            dividendType = 'FULLDIV';
            dividendPrice = optCharges['FULLDIV'];

        } 
        if(optCharges['HALFDIV']>0){
            totAmt = totAmt - optCharges['HALFDIV'];
            dividendType = 'HALFDIV';
            dividendPrice = optCharges['HALFDIV'];
        }

        return {monthlyPayment:totAmt, seals:seals, dividendType:dividendType, dividendPrice:dividendPrice, ded_code:ded_code}

    }

    updatePlan = async() => {
        const { showLoader, hideLoader } = this.props
        var {dealertoken, optCharges, recordId, dedIdx, plans, planInfo, planIdx, lead, 
            vehi, canSubmit, loggeduserid, sequma} = this.state;
        
        if(1){
            this.noCoupon();
            showLoader();
            let payInfo = this.getBreakUp();
            var nowPlan = plans[planIdx];

            let model = this.refs[`quoteFrom`].getModel()
                               
            let reqin = {
                keepVehicle: 60,
                levelOfCoverage: nowPlan['name'],
                payAtClaimTime: dedIdx,
                milesPerMonthMin : 0,
                milesPerMonthMax : 0,
                monthlyPayment : payInfo.monthlyPayment,
                activationCost : 0,
                covered : nowPlan['covered'],
                rateId : nowPlan['rateId'],
                recordId : recordId,
                vehicleId : planInfo.vehicleId,
                userId : planInfo.userId,
                deductibleCode : payInfo.ded_code,
                deductible : payInfo.ded_code,
                quote:1,
                loggeduserid:loggeduserid,
                seals:payInfo.seals,
                dividendType:payInfo.dividendType,
                dividendPrice:payInfo.dividendPrice,
                optCharges:optCharges,
                customer:{
                    firstName: model.firstName,
                    lastName: model.lastName,
                    phone: model.phone,
                    address: model.address,
                    city: model.city,
                    zipcode: model.zipcode,
                    state:lead.state
                },
                sequma:sequma,
                calfrm:'crm'
            }
            //console.log(reqin)
            let uri = process.env.REST_API_URL+'saveplan'+(dealertoken?'/?dealertoken='+dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(reqin)
            }).then(res => res.json()).then((res) => {
                if(res.status==="Success"){
                    this.setState({ showPay: true});
                    this.state.showPay = true;
                    hideLoader();
                }
            });
        }
    }
    
    sendFirstContact = async() => {
        const { showLoader, hideLoader } = this.props
        var {dealertoken, planInfo, loggeduserid} = this.state;
        showLoader();
        let uri = process.env.REST_API_URL+'sendFirstContact'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userId:planInfo.userId,loggeduserid:loggeduserid})
        }).then(res => res.json()).then((res) => {
            if(res.status){
                hideLoader();
            }
        });

    }

    sendQuoteLink = async() => {
        const { showLoader, hideLoader } = this.props
        var {dealertoken, recordId, loggeduserid} = this.state;
        showLoader();
        let uri = process.env.REST_API_URL+'sendQuoteLink'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({recordId:recordId,loggeduserid:loggeduserid})
        }).then(res => res.json()).then((res) => {
            if(res.status){
                hideLoader();
            }
        });

    }

    sendPayLink = async() => {
        const { showLoader, hideLoader } = this.props
        var {dealertoken, recordId, paylnk, loggeduserid} = this.state;
        showLoader();
        let uri = process.env.REST_API_URL+'sendPayLink'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({recordId:recordId,loggeduserid:loggeduserid})
        }).then(res => res.json()).then((res) => {
            if(res.status){
                this.setState({ paylnk: null});
                hideLoader();
            }
        });

    }

    enablePay = () => {
        this.setState({ canPay: true});
    }
    
    disablePay = () => {
        this.setState({ canPay: false});
    }

    addCard = async(model) => {
        const { showLoader, hideLoader } = this.props;
        const {planInfo, dealertoken} = this.state;
        showLoader();
        //await this.makePay();
        let uri = process.env.REST_API_URL+'checkcard'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                recordId:planInfo.recordId,
                userId:planInfo.userId,
                cardFirstName: model.cardFirstName,
                cardLastName: model.cardLastName,
                cardNo: model.cardNo,
                cvv:model.cvv,
                expMMYY: model.expMMYY,
            })
          })
        .then(res => res.json())
        .then(async(res) => {
            if(res.status === 0){
                let errorCode='';
                errorCode = res.type;
                if(errorCode === 'cardNo'){
                    this.refs[`payFrom`].updateInputsWithError({
                        [`${errorCode}`]: res.msg,
                    });
                    this.setState({ gatewayerror: null })
                } else {
                    this.setState({ gatewayerror: res.msg })
                }
                hideLoader();
            } else {
                console.log('wait')
                await new Promise(resolve => setTimeout(resolve, 15000)); 
                console.log('pay')
                await this.makePay();
            }
        });
    }

    makePay = async() => {
    
        const { showLoader, hideLoader } = this.props;
        const { planInfo, dealertoken, plans,planIdx, loggeduserid,discountCode,dateVal,postpay  } = this.state;
        
        showLoader();
        let payInfo = this.getBreakUp();
        var nowPlan = plans[planIdx];
        
        let uri = process.env.REST_API_URL+'makepay'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            _id: planInfo.recordId,
            dividendType:payInfo.dividendType,
            dividendPrice:payInfo.dividendPrice,
            Seals:payInfo.seals,
            retailPrice : payInfo.monthlyPayment,
            productCode: nowPlan.productCode,
            loggeduserid:loggeduserid,
            coupon:discountCode,
            startDate:dateVal,
            postpay:postpay
          })
        })
        .then(res => res.json())
        .then((res) => {
            hideLoader();
            if (res.status ===1) {
              navigate(`/congratulation/?q=${res.contractId}&id=`+planInfo.recordId+(dealertoken?'&dealertoken='+dealertoken:''));
            } else {
              this.setState({ gatewayerror: res.msg })
            }
        });
    };

    couponChange = (val) => {
        this.setState({couponCode:val})
        if(val.length>3){
          this.setState({couponStat:2})
        } else {
          this.setState({couponStat:1})
        }
    }
    dateChange = (dt) => {
     if(dt.length == 10){
        let splt = dt.split("/");
        let eDt = new Date(splt[2]+'-'+splt[0]+'-'+splt[1]);
        let nDt = new Date(this.getMyDate(1,0));
        let diffTime = (eDt - nDt);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        console.log(eDt,nDt, diffTime, diffDays);

        if(diffDays>1){
            this.setState({dateVal:dt})
        } else {
            this.setState({dateVal:null})
        }
        
        //let dif = eDt.getDate() - nDt.getDate();
        //console.log(dif+ '-------'+ splt[2]+'-'+splt[0]+'-'+splt[1])
     } else {
        this.setState({dateVal:null})
     }
      
    }
    getMyDate = (isToday,disply) => {
        let dte = new Date();
        if(isToday==0){
            dte.setDate(dte.getDate() + 1);
        }
        var dd = String(dte.getDate()).padStart(2, '0');
        var mm = String(dte.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = dte.getFullYear();
        if(disply)
        return (mm + '/' + dd + '/' + yyyy);
        else
        return (yyyy + '/' + mm + '/' + dd);
    }

      applyCoupon = async() =>{
        const { showLoader, hideLoader } = this.props;
        const { couponCode,dealertoken} = this.state;
        showLoader();
        
        let uri = process.env.REST_API_URL+'checkCoupon'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            coupon:couponCode,
          })
        })
        .then(res => res.json())
        .then((res) => {
            hideLoader();
            if (res.valid ===1) {
              //console.log(res)
              this.setState({ couponStat:3, discountType:res.type, discountVal:res.value,discountCode:couponCode })
            } else {
              this.setState({ couponStat:4, discountType:null, discountVal:0,discountCode:null })
            }
            this.getTotAmt();
        });
      }
      showCoupon = () => {
        this.setState({couponStat:1})
      }

    
    render() {
        const { lead,yearList,makeList,modelList,canSubmit,vehi,noplan,plans,planInfo, deducts,curplan,planIdx,surcList,
            optCharges,totAmt,showPay,canPay,gatewayerror,paylnk,msg,loggeduserid,userType, sequma,
            couponStat,discountAmt, dateVal, initdate,postpay } = this.state;
        
        if(!lead){
            return (
                <Layout layoutType="main"> 
                <SEO title="Quick Quote" keywords={[`CRM`, `Quote`, `Service Contract`]} />
                    <section><div className="custom-container"><div className="body-content">
                    <h2 className="body-title">Lead Info</h2><p className="error-txt">{msg}</p></div></div> </section> </Layout>
            );
        }

        

        if(lead && lead.email){
            return (
            
            <Layout layoutType="main">
            <SEO title="Quick Quote" keywords={[`CRM`, `Quote`, `Service Contract`]} />
            <header id="pageHeader">
                <div className="inner-header">
                    <div className="header-content">
                    <div className="logo">
                        <img src={planInfo.logoUrl} alt="Logo" />
                    </div>
                    </div>
                </div>
            </header >
            <section>
            <div className="custom-container">
            <div className="body-content">
            <h2 className="body-title">Lead Info
            {userType=="emp" && plans &&
                <div onClick={() => { this.sendQuoteLink() }}  className="btn mini">Send This Link</div>
            }{userType=="emp" && plans &&
                <div onClick={() => { this.sendFirstContact() }}  className="btn mini">First Contact</div>
            }
            </h2>
            <Formsy ref="quoteFrom" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
            <ErrrorHandel formName={'quoteFrom'} self={this} />
            
                <div className="lead">
                    <div className="form-container-inline">
                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyInput valueChanges={(e) => this.tbvalChanges(e)} name="firstName" id="firstName" placeholder="First Name *" value={lead.firstName} isLabelRequired={true} className="form-control" required/>
                        </div>
                        </div>

                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyInput valueChanges={(e) => this.tbvalChanges(e)} name="lastName" id="lastName" placeholder="Last Name *" value={lead.lastName} isLabelRequired={true} className="form-control" required/>
                            </div>
                        </div>

                        <div className="form-4">
                            <div className="form-label-group error">
                                <MyInput valueChanges={(e) => this.tbvalChanges(e)} name="email" id="email" validations="isEmail" value={lead.email} validationError="Please enter valid email" placeholder="Email *" isLabelRequired={true} className="form-control" required />
                            </div>
                        </div>
                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyInputMask valueChanges={(e) => this.tbvalChanges(e)} name="phone" id="phone" mask="999-999-9999" placeholder="Phone *" value={lead.phone}  validations={{ minLength: 12 }} validationError="Please enter a valid mobile number." isLabelRequired= {true} className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="form-container-inline">
                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyInput valueChanges={(e) => this.tbvalChanges(e)} name="address" type="text" id="address" placeholder="Street Address" value={lead.address} className="form-control" validations={{minLength: 3,maxLength:40}} validationError="Please enter a valid address." isLabelRequired={true}/>
                            </div>
                        </div>

                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyInput valueChanges={(e) => this.tbvalChanges(e)} name="city" id="city" type="text" placeholder="City" value={lead.city} className="form-control" isLabelRequired={true} validations={{minLength: 3,maxLength:30}} validationError="Please enter a valid city." />
                            </div>
                        </div>

                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyAuto value={lead.state} options={zonar} name="zone" placeholder="State *" sugChange={(e) => this.sugChange(e, 'zone')} />
                            </div>
                        </div>

                        <div className="form-4">
                            <div className="form-label-group error">
                            <MyInputMask valueChanges={(e) => this.tbvalChanges(e)} name="zipcode" id="zipcode" placeholder="Zipcode" value={lead.zipcode} mask="99999"  isLabelRequired={true} className="form-control" validations={{ minLength: 5 }} validationError="Please enter a valid zipcode." />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="Vehcile">
                 <h2 className="body-title">Vehicle Info</h2>
                    <div className="form-container-inline">
                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyInputMask name="vehicleInfo.vin" value={vehi.vehicleInfo.vin} id="vehvin" mask="*****************" valueChanges={(e) => this.vinChanges('quoteFrom',this, e)} placeholder="VIN" className="form-control" validations={{maxLength: 17,minLength: 17}} isLabelRequired={true} validationError="Please enter a valid VIN." />
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyAuto value={vehi.vehicleInfo.year} required options={yearList} name="vehicleInfo.year" placeholder="Year *" sugChange={(e) => this.sugChange(e, 'year')}  />
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyAuto value={vehi.vehicleInfo.maker} required options={makeList} name="vehicleInfo.maker" placeholder="Make *" sugChange={(e) => this.sugChange(e, 'make')}  />
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyAuto value={vehi.vehicleInfo.model} required options={modelList} name="vehicleInfo.model" placeholder="Model *" sugChange={(e) => this.sugChange(e, 'model')}  />
                        </div>
                        </div>

                    </div>
                    <div className="form-container-inline">
                        <div className="form-4">
                        <div className="form-label-group error">
                        <MyInputMask valueChanges={(e) => this.tbvalChanges(e)} name="vehicleInfo.mileage" value={vehi.vehicleInfo.mileage.toString()} id="mileage" mask="999999" placeholder="Mileage *" className="form-control"  isLabelRequired={true} validations="isNumeric" validationError="Please enter a valid number." required />
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="check-box-container">
                        <div className={vehi.vehicleInfo.vin?"form-check gryout":"form-check"}>
                        <MyCheckbox name="vehicleFeature.isAWD4x4" value={vehi.vehicleFeature.isAWD4x4} checkBoxChange={(e) => this.radioChanges('isAWD4x4', this, e)} id="cbx1" labelClass="check-container big"  label="AWD/4x4" disabled={vehi.vehicleInfo.vin?true:false} />
                        </div>
                        <div className={vehi.vehicleInfo.vin?"form-check gryout":"form-check"}>
                        <MyCheckbox name="vehicleFeature.isTurboSuper" value={vehi.vehicleFeature.isTurboSuper} checkBoxChange={(e) => this.radioChanges('isTurboSuper', this, e)} id="cbx2" labelClass="check-container big"  label="Turbo Supercharger" disabled={vehi.vehicleInfo.vin?true:false}  />
                        </div>
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="check-box-container">
                        <div className={vehi.vehicleInfo.vin?"form-check gryout":"form-check"}>
                        <MyCheckbox name="vehicleFeature.isDiesel" value={vehi.vehicleFeature.isDiesel}  checkBoxChange={(e) => this.radioChanges('isDiesel', this, e)} id="cbx3" labelClass="check-container big round"  label="Diesel" disabled={vehi.vehicleInfo.vin?true:false}  />
                        </div>
                        <div className={vehi.vehicleInfo.vin?"form-check gryout":"form-check"}>
                        <MyCheckbox name="vehicleFeature.isHybrid" value={vehi.vehicleFeature.isHybrid} checkBoxChange={(e) => this.radioChanges('isHybrid', this, e)} id="cbx4" labelClass="check-container big round"  label="Hybrid" disabled={vehi.vehicleInfo.vin?true:false}  />
                        </div>
                        <div className={vehi.vehicleInfo.vin?"form-check gryout":"form-check"}>
                        <MyCheckbox name="vehicleFeature.isElectric" value={vehi.vehicleFeature.isElectric} checkBoxChange={(e) => this.radioChanges('isElectric', this, e)} id="cbx5" labelClass="check-container big round"  label="Electric" disabled={vehi.vehicleInfo.vin?true:false}  />
                        </div>
                        </div>
                        </div>

                        <div className="form-4">
                        <div className="form-label-group error">
                        <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled' : 'btn'}>
                            Get Plan
                        </button>
                        
                        </div>
                        </div>
                    </div>
                </div>
                <div className="Plans">
                    {plans &&
                    <>
                        
                        <div className="form-70">
                        <h2 className="body-title">Products</h2>
                        <div className="form-container-inline">
                            <table>
                                <thead>
                                <tr><th rowSpan="2">Plan Name</th><th rowSpan="2">Coverge</th><th rowSpan="2">Basic</th>
                                <th colSpan={Object.keys(deducts).length}>Deduct</th></tr>
                                <tr>
                                {
                                    Object.keys(deducts).map((k)=> {
                                        return (
                                            <th key={k}>${k}</th>
                                        )
                                    })
                                }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(plans).map((k1) => {
                                        return (
                                            <tr key={k1}>
                                                <td>{plans[k1]['name']}</td><td>{plans[k1]['covered']}%</td><td>${plans[k1]['actual']}</td>
                                                {
                                                    Object.keys(deducts).map((k2) => {
                                                        return (
                                                            <td 
                                                                key={k1+k2} 
                                                                onClick={() => { this.changePlan(k1,k2,plans[k1]['deduct'][k2],plans[k1]['actual']) }} 
                                                                className={curplan===(k1+k2)?'tdbtn curnt':'tdbtn'}>
                                                                {plans[k1]['deduct'][k2]>=-100 ? plans[k1]['deduct'][k2]+plans[k1]['actual']:''}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        </div>

                        <div className="form-30">
                        <h2 className="body-title">Surcharges</h2>
                        <div className="check-box-container">
                        {
                            Object.keys(surcList).map((s) => {
                                return(
                                    <div className={plans[planIdx]['surc'][s] ? "surc-check" : "surc-check gryout"} key={'div1'+s}>
                                        {plans[planIdx]['surc'][s] ?
                                            <MyCheckbox 
                                                name={"surc."+surcList[s]} 
                                                value={optCharges[s]}  
                                                checkBoxChange={(e) => this.surcChanges(s, this, e, plans[planIdx]['surc'][s])} 
                                                id={"cbx_"+surcList[s]}
                                                labelClass="check-container big"  
                                                label={s+"($"+plans[planIdx]['surc'][s]+")"}
                                            />
                                            :
                                            <MyCheckbox 
                                                name={"surc."+surcList[s]} 
                                                id={"cbx_"+surcList[s]}
                                                labelClass="check-container big"  
                                                label={s}
                                                disabled = {true}
                                            />
                                        }
                                    </div>
                                )
                                
                            })
                        }
                        
                        {totAmt>0  &&
                        <div onClick={() => { this.updatePlan() }}  className="btn">Save Quote</div>
                        }
                        {totAmt>0 && userType=="emp" &&
                        <div className="surc-check">
                        <MyCheckbox 
                            name="sendmail" 
                            value={sequma} 
                            id="sendmail"
                            labelClass="check-container big"  
                            label="Send to customer"
                            checkBoxChange={(e) => this.mailcheck(e)} 
                        />
                        </div>
                        }
                        </div>
                        <p><b>Total Amount : ${totAmt?totAmt:0}</b></p>
                        </div>
                    </>
                    }
                    {noplan && <div className="form-container-inline"><div className="error-txt">{noplan}</div></div>}
                    
                </div>
            </Formsy>
            {showPay &&
                <Formsy ref="payFrom" onValidSubmit={this.addCard} onValid={this.enablePay} onInvalid={this.disablePay}>
                <ErrrorHandel formName={'payFrom'} self={this} />
                
                    <div className="payiv">
                    
                    {(vehi.vehicleInfo.vin && lead.city && lead.zipcode && lead.address) ?
                        <div>
                        <div className="form2-30">
                        {/* <div className="form-container-inline">
                        <div className="ccout">
                        <div className="cctitle">
                        <MyCheckbox 
                            name="postpay" 
                            value={postpay} 
                            id="postpay"
                            labelClass="check-container big"  
                            label="Post Payment Date (MM/DD/YYYY)"
                            checkBoxChange={(e) => this.postpay(e)} 
                        />
                        </div>
                        {postpay>0 &&
                        <div>
                        <MyInputMask name="startdate" value={initdate} id="startdate" mask="99/99/9999" className="ccbox" 
                        valueChanges={(e) => this.dateChange(e)} />
                        {!dateVal &&
                        <div className="ccerr">Invalid Date</div>
                        }
                        </div>
                        }
                        
                        </div>
                        </div> */}
                        
                        

                        <div className="form-container-inline">
                        <div className="ccout">
                            <div className="cctitle" onClick={() => {this.showCoupon()}}>Have a Coupon?</div>
                            {couponStat>0 &&
                            <div>
                            <MyInput
                                name="coupon"
                                type="text"
                                placeholder="Coupon Code"
                                value=""
                                className="ccbox"
                                valueChanges={(e) => this.couponChange(e)}
                            />
                            {couponStat==4 &&
                            <div className="ccerr">Invalid Coupon Code</div>
                            }
                            {couponStat==3 &&
                            <div className="ccok">Discount Applied : ${discountAmt}</div>
                            }
                            <button onClick={() => {this.applyCoupon()}} className={couponStat==1?"ccbtn disabled":"ccbtn"}>Apply</button>
                            
                            </div>
                            }
                            </div>
                        </div>
                        </div>
                        <div className="form2-70">
                        <h2 className="body-title">Credit Card Info</h2>    
                        <div className="form-container-inline">
                            <div className="form-22">
                            <div className="form-label-group error">
                            <MyInput name="cardFirstName" id="cardFirstName" placeholder="Card First Name" value={lead.firstName}  isLabelRequired={true} className="form-control" required/>
                            </div>
                            </div>

                            <div className="form-22">
                            <div className="form-label-group error">
                            <MyInput name="cardLastName" id="cardLastName" placeholder="Card Last Name" value={lead.lastName} isLabelRequired={true} className="form-control" required/>
                            </div>
                            </div>
                        </div>
                        <div className="form-container-inline">
                            <div className="form-22">
                            <div className="form-label-group error">
                            <MyInputMask name="cardNo" value={''} id="cardNo" mask="9999 9999 9999 9999" placeholder="Credit Card Number" validations={{ maxLength: 19, minLength: 16 }} validationError="Please enter a valid card number."  isLabelRequired= {true} className="form-control" required />
                            </div>
                            </div>

                        

                            <div className="form-22">
                            <div className="form-label-group error">
                            <MyInputMask name="expMMYY" value={''} id="expMMYY" mask="99/99" placeholder="Exp Date (MM/YY)" validationError="Please enter a valid MM/YY." isLabelRequired= {true} className="form-control" required />
                            </div>
                            </div>
                        </div>
                        <div className="form-container-inline">
                            <div className="form-22">
                            <div className="form-label-group error">
                            <MyInputMask name="cvv" value={''} id="cvv" mask="999" placeholder="CVV" validations={{ maxLength: 3, minLength: 3 }} validationError="Please enter a valid CVV."  isLabelRequired= {true} className="form-control" required />
                            </div>
                            </div>

                            <div className="form-22">
                            <div className="form-label-group error">
                            <button type="submit" disabled={!canPay || !dateVal} className={!canPay || !dateVal ? 'pynw btn disabled' : 'pynw btn'}>
                            Confirm
                            </button>
                         
                            </div>
                            </div>

                        </div>
                        <div className="form-container-inline">
                            <div className="form-label-group error">
                            <div className="error-txt">{gatewayerror}</div>
                            </div>
                        </div>
                        </div>
                        </div>
                        :
                        <div className="error-txt">
                            Add VIN and Address details 
                        </div>
                    }
                    

                        </div>
                        
                    
                </Formsy>
            }
            </div>
            </div>

            </section>
            </Layout>
            );
        } 
    }
}



const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotePage);

