import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
  
  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  
  
  class MyAuto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            master :this.props.options,
            name:this.props.name,
            placeholder:this.props.placeholder,
            suggestions: []
        };
    }

     escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getSuggestions(value) {
        var escapedValue = "";
        if(value){
          value = value.toString();
          escapedValue =  this.escapeRegexCharacters(value.trim());
        }
        const regex = new RegExp( "^" +escapedValue, 'i');
        return this.state.master.filter(item => regex.test(item));
      }
      
      getSuggestionValue(suggestion) {
        return suggestion; 
      }
      
      
      renderSuggestion(suggestion) {
        return (
          <span>{suggestion}</span>
        );
      }

    toTitleCase(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
  
    onChange = (event, { newValue, method }) => {
      this.checkVal(newValue)
      this.setState({ value: newValue });
    };

    onBlur = (event) => {
      //this.checkVal(event.currentTarget.value)
    };
    sugSelected = (e,val) =>{
      this.checkVal(val.suggestionValue)
    }

    checkVal = (val) => {
      
      val = this.getCase(val);
      this.setState({ value: val });
      
      if(this.state.master.includes(val))
        this.props.sugChange(val);
        
      /* else if(this.state.name === "make")
        this.props.sugChange(''); */
    }

    getCase = (val) => {
      
      if(this.state.name === "zone" || this.state.name === "title"){
        return this.toTitleCase(val)
      } else if(this.state.name === "vehicleInfo.maker" || this.state.name === "vehicleInfo.model"){
        return val.toUpperCase();
      } else {
        if(val!=="")
          return parseInt(val);
        else
          return '';
      }
    }
   

   
    shouldRenderSuggestions() {
      return true;
    }
    
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };
  
    setmaster =(options,value) =>{
      if(options.length!==this.state.master.length){
        this.state.master=options;
      } else if(this.state.master.length===1){
        if(options[0]!==this.state.master[0]){
          this.state.master=options;
        }
      } 
      if(value!==this.state.value){
        this.state.value=value;
      }
    }

    render() {
      const { value, suggestions,placeholder } = this.state;
      this.setmaster(this.props.options,this.props.value);
      //console.log(name+':'+value)
      //this.setState({master:suggestions});
      const inputProps = {
        value,
        onChange: this.onChange,
        onBlur:this.onBlur,
        placeholder:placeholder,
        autoComplete:"nope"
      };
  
      return (
        
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.sugSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          inputProps={inputProps}
        />
      );
    }
  }
  
  export default MyAuto;
  