import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';


class MySelect extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }
  // mixins: [Formsy.Mixin],

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
    if (this.props.selecteChange) {
      this.props.selecteChange(event.currentTarget.value);
    }
  }

  render() {
    const className = 'form-group' + (this.props.className || ' ') +
      (this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : '');
    const errorMessage = this.props.getErrorMessage();

    const options = this.props.options.map((option, i) => (
      
      <option key={option.value? (option.value+i) : option} value={option.value ? option.value : option}>
        &nbsp; {'Month - '}{option.title ? option.title : option}
      </option>
      
      
    ));

    return (
      <div className={className}>
        <label htmlFor={this.props.name}>{this.props.title}</label>
        <select name={this.props.name} onChange={this.changeValue} value={this.props.getValue() || ''}>
          {options}
        </select>
        <div className="select-arrow">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11.1" viewBox="0 0 18 11.1">
            <g id="keyboard-right-arrow-button" transform="translate(18) rotate(90)">
              <g id="chevron-right" transform="translate(0)">
              <path d="M487.536,120.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,347.581L18.203,120.467c-4.16-4.16-10.923-4.16-15.083,0
			c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136l234.667-234.667
			C491.696,131.368,491.696,124.605,487.536,120.445z"/>
              </g>
            </g>
          </svg> */}
          <span>&#9662;</span>
        </div>
        <span className='validation-error'>{errorMessage}</span>
      </div>
    );
  }
}

export default withFormsy(MySelect);